import React from 'react';
import Layout from '@studionand/depi/src/components/DepvLayout';
import Editor from '@studionand/depi/src/components/depv/Editor';
import config from '@studionand/depi/src/components/depv/Header/constants';
import NamedPage from '@studionand/depi/src/components/NamedPage';

const DepvNamedPage = props => {
  return (
    <NamedPage
      {...props}
      config={config}
      layoutComponent={Layout}
      editorComponent={Editor}
    />
  );
};

DepvNamedPage.getInitialProps = NamedPage.getInitialProps(config);

export default DepvNamedPage;
